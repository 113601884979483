import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import showdown from 'showdown'

const AboutUs = styled.div`
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: var(--bp-xlarge);
  margin: 0 auto;
  h1 {
    display: block;
    text-align: center;
  }
`

const AboutUsPeople = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  h2 {
    width: 100%;
    margin-bottom: 2rem;
  }
`

const AboutUsPerson = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    width: 45%;
  }
  img {
    max-width: 100%;
    height: 250px;
    width: auto;
    margin-bottom: 2rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 25px 0px rgba(84, 131, 157, 0.2);
  }
`

const AboutUsBeforeFooter = styled.div`
  width: 100%;
  padding: 3rem 0 0 0;
  border-top: 1px solid #ccc;
  margin-top: 4rem;
  margin-bottom: 4rem;
`

const converter = new showdown.Converter()

export default function AboutUsPage() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: {about_body: {regex: "^/"}}) {
        frontmatter {
          about_body
          about_before_footer
        }
      }
      allMarkdownRemark(filter: {frontmatter: {person_name: {regex: "^/"}}}) {
        edges {
          node {
            frontmatter {
              person_name
              person_phone
              person_bio
              person_image
              person_email
            }
          }
        }
      }
    }
  `)
  return (
    <AboutUs>
      <h1>About us</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(
            data.markdownRemark.frontmatter.about_body
          ),
        }}
      />
        <AboutUsPeople>
          <h2>Our team</h2>
          {data.allMarkdownRemark.edges.map(({node}) => (
            <AboutUsPerson>
              <img src={node.frontmatter.person_image} alt={node.frontmatter.person_name} />
              <p><b>{node.frontmatter.person_name}</b></p>
              <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(
                      node.frontmatter.person_bio
                    ),
                  }}
                />
              { node.frontmatter.person_phone && (
                <p><b>Tel: </b>{node.frontmatter.person_phone}</p>
              )}
              <p><b>E-mail: </b>{node.frontmatter.person_email}</p>
            </AboutUsPerson>
          ))}
          <AboutUsBeforeFooter>
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(
                  data.markdownRemark.frontmatter.about_before_footer
                ),
              }}
            />
          </AboutUsBeforeFooter>
        </AboutUsPeople>
    </AboutUs>
  )
}


