import * as React from "react"
import Layout from '../components/Layout'
import AboutUsTemplate from "../templates/About"


const AboutUsPage = () => {
  return (
    <Layout>
      <AboutUsTemplate />
    </Layout>
  )
}

export default AboutUsPage
